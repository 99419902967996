<template>
  <div>
    <custom-vue-select
      id="donation-category-select"
      label="Select Donation Category"
      placeholder="Type 3 chars to search categories"
      :options="categories"
      text-field="category_name"
      :value-reducer="option=>option"
      :disabled="disabled"
      :data-provider="getDonationCategories"
      :selected.sync="categoryProxy"
    />
  </div>
</template>
<script>
import CustomVueSelect from '../../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'DonationCategorySelect',
  components: { CustomVueSelect },
  props: {
    category: { type: Object, default: () => {} },
    organization: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      categories: [],
    }
  },
  computed: {
    categoryProxy: {
      get() {
        return this.category
      },
      set(value) {
        this.$emit('update:category', value)
      },
    },
  },
  methods: {
    getDonationCategories(query) {
      this.$entities.get('internalops/donation-categories', {
        params: {
          name: query,
          entityId: this.organization.id,
        },
      }).then(res => {
        this.categories = res.data.data
      })
    },
  },
}
</script>
<style lang="">

</style>
