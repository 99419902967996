<template>
  <div class="border p-2 my-3">
    <h4 class="mb-1">
      Media
    </h4>

    <b-form-group
      label="Placeholder Size"
      label-for="placeholder-size"
    >
      <b-select
        v-model="mediaRatioProxy"
        :options="[
          {text:'Please select ratio',value:null,disabled:true},
          {text:'Landscape 16x9',value:'landscape'},
          {text:'Portrait 9x16',value:'portrait'},
          {text:'Square 1x1',value:'square'}
        ]"
      />
    </b-form-group>

    <b-form-group
      label="Slider Type"
      label-for="slider-type"
    >
      <b-form-radio-group
        id="slider-type"
        v-model="sliderTypeProxy"
        :options="[
          { text: 'Video', value: 'video' },
          { text: 'Image gallery', value: 'images' },
        ]"
      />
    </b-form-group>

    <b-form-row v-if="sliderTypeProxy === 'video'">
      <b-col lg="6">
        <text-input
          id="youtube-link"
          name="Youtube Link"
          :rules="`youtube-url|${isEditForm ?'':'required'}`"
          :text.sync="youtubeLinkProxy"
        />
      </b-col>

      <b-col cols="12">
        <b-form-row>
          <b-col lg="6">
            <upload-files
              id="announcement-yt-image"
              label="Youtube Cover"
              accept="image/*"
              :rules="`image|image-aspect-ratio:${mediaRatioDimensions}`"
              :files.sync="youtubeCoverProxy"
            >
              <template #preview>
                <div>
                  <images-preview
                    :images.sync="youtubeCoverProxy"
                  />
                </div>
              </template>
            </upload-files>
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>

    <!-- Images Slider -->
    <b-form-row
      v-else
      class="my-1 border rounded p-2"
    >
      <b-col cols="12">
        <upload-files
          id="announcement-images"
          label="Upload Images"
          accept="image/*"
          :rules="`image|image-aspect-ratio:${mediaRatioDimensions}|${isEditForm ?'':'required'}`"
          multiple
          :files.sync="imagesProxy"
        >
          <template #preview>
            <div>
              <images-preview
                :images.sync="imagesProxy"
              />
            </div>
          </template>
        </upload-files>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import TextInput from '../common/FormInputs/TextInput.vue'
import ImagesPreview from '../common/FormInputs/ImagesPreview.vue'
import UploadFiles from '../common/FormInputs/UploadFiles.vue'

export default {
  name: 'AnnouncementMedia',
  components: { UploadFiles, ImagesPreview, TextInput },
  props: {
    media: { type: Array, default: () => [] },
    youtubeLink: { type: String, default: '' },
    youtubeCover: { type: Array, default: () => [] },
    mediaRatio: { type: String, default: 'landscape' },
    sliderType: { type: String, default: 'images' },
  },
  computed: {
    sliderTypeProxy: {
      get() {
        return this.sliderType
      },
      set(value) {
        if (value === 'images') {
          this.youtubeCoverProxy = []
          this.youtubeLinkProxy = ''
        } else {
          this.imagesProxy = []
        }
        this.$emit('update:sliderType', value)
      },
    },
    isEditForm() {
      return !!this.$route.params.id
    },
    youtubeLinkProxy: {
      get() {
        return this.youtubeLink
      },
      set(value) {
        this.$emit('update:youtubeLink', value)
      },
    },
    youtubeCoverProxy: {
      get() {
        return this.youtubeCover
      },
      set(value) {
        this.$emit('update:youtubeCover', value)
      },
    },
    mediaRatioProxy: {
      get() {
        return this.mediaRatio
      },
      set(value) {
        this.$emit('update:mediaRatio', value)
      },
    },
    imagesProxy: {
      get() {
        return this.media
      },
      set(images) {
        this.$emit('update:media', images)
      },
    },
    mediaRatioDimensions() {
      const dimensions = {
        landscape: '16,9',
        portrait: '9,16',
        square: '1,1',
      }

      return dimensions[this.mediaRatio]
    },
  },
}
</script>
<style lang="">

</style>
