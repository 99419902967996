<template>
  <div>
    <custom-vue-select
      id="organizations-search"
      label="Select Organization"
      placeholder="Type 3 char to search organizations"
      :options="organizations"
      text-field="name"
      :value-reducer="option=>option"
      :data-provider="getOrganizations"
      :selected.sync="organizationProxy"
    />
  </div>
</template>
<script>
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'OrganizationsSearch',
  components: { CustomVueSelect },
  props: {
    organization: { type: Object, default: null },
  },
  data() {
    return {
      organizations: [],
    }
  },
  computed: {
    organizationProxy: {
      get() {
        return this.organization
      },
      set(value) {
        this.$emit('update:organization', value)
      },
    },
  },
  methods: {
    getOrganizations(query) {
      this.$entities.get('internalops/entity/list/', {
        params: {
          search_str: query,
        },
      }).then(res => {
        this.organizations = res.data.data.data
      })
    },
  },
}
</script>
<style lang="">

</style>
