<template>
  <div class="border p-2 my-2">
    <h4 class="mb-2">
      Call To Action
    </h4>

    <b-form-group
      label="CTA Type"
      label-for="cta-type"
    >
      <b-select
        id="cta-type"
        v-model="ctaTypeProxy"
        :options="ctaOptions"
      />
    </b-form-group>

    <!-- External Url -->
    <div
      v-if="announcement.cta_type === 'external link'"
    >
      <text-input
        id="action-url"
        name="Action URL"
        rules="url"
        :text.sync="announcement.cta_value"
      />
    </div>

    <div
      v-if="announcement.cta_type"
    >
      <b-form-checkbox
        id="custom-section"
        v-model="announcement.is_custom_section"
        class="mb-1"
        switch
      >
        Custom Section
      </b-form-checkbox>

      <div v-show="announcement.is_custom_section">
        <!-- Caption -->
        <text-input
          id="cta-caption"
          name="Caption"
          :rules="`max:200|${announcement.is_custom_section?'required':''}`"
          :text.sync="announcement.cta_caption"
        />
        <!-- Button Text -->
        <text-input
          id="cta-button-text"
          name="Button Text"
          :rules="`max:20|${announcement.is_custom_section?'required':''}`"
          :text.sync="announcement.cta_button_text"
        />
      </div>

      <upload-files
        id="cta-icon"
        label="Upload Icon"
        accept="image/svg+xml"
        rules="ext:svg"
        :files.sync="announcement.cta_icon"
      >
        <template #preview>
          <div>
            <images-preview
              :images.sync="announcement.cta_icon"
            />
          </div>
        </template>
      </upload-files>
    </div>

    <!-- FundraisingProjectSelect -->
    <fundraising-project-select
      v-if="announcement.cta_type === 'Fundraising Project'"
      :filter="{active:true,upcoming:true}"
      :fundraising-id.sync="announcement.cta_value"
    />

    <select-event
      v-if="announcement.cta_type === 'Event'"
      :event.sync="announcement.cta_value"
    />

    <div
      v-if="announcement.cta_type === 'Donation to specific Organization'"
    >
      <organizations-search :organization.sync="announcement.ctaOrganization" />

      <donation-category-select
        :organization="announcement.ctaOrganization"
        :category.sync="announcement.ctaDonationCategory"
        :disabled="!announcement.ctaOrganization"
      />
    </div>

  </div>
</template>
<script>
import FundraisingProjectSelect from '@/common/components/Fundraising/FundraisingProjectSelect.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import DonationCategorySelect from '../GeneralSettings/donation-categories/DonationCategorySelect.vue'
import OrganizationsSearch from '../Inpos/OrganizationsSearch.vue'
import SelectEvent from '../QuickEvents/Bookings/SelectEvent.vue'
import ImagesPreview from '../common/FormInputs/ImagesPreview.vue'
import UploadFiles from '../common/FormInputs/UploadFiles.vue'

export default {
  name: 'AnnouncementCTA',
  components: {
    TextInput,
    FundraisingProjectSelect,
    SelectEvent,
    OrganizationsSearch,
    DonationCategorySelect,
    UploadFiles,
    ImagesPreview,
  },
  props: {
    announcement: { type: Object, default: () => {} },
  },
  computed: {
    ctaOptions() {
      return [
        { text: 'No Action', value: null },
        { text: 'Do Azkar', value: 'Do Azkar' },
        { text: 'Quran', value: 'Quran' },
        { text: 'Qibla', value: 'Qibla' },
        { text: 'Do Radio', value: 'Do Radio' },
        { text: 'Do Fundraising Landing', value: 'Do Fundraising Landing' },
        { text: 'Do Events Landing', value: 'Do Events Landing' },
        { text: 'Do Offers', value: 'Do Offers' },
        { text: 'Do TV Landing', value: 'Do TV Landing' },
        { text: 'Do Mall', value: 'Do Mall' },
        { text: 'Islamic Orgs Directory', value: 'Islamic Orgs Directory' },
        { text: 'Fundraising Project', value: 'Fundraising Project' },
        { text: 'Event', value: 'Event' },
        { text: 'Donation to specific Organization', value: 'Donation to specific Organization' },
        { text: 'External Link', value: 'external link' },
      ]
    },
    ctaTypeProxy: {
      get() {
        return this.announcement.cta_type
      },
      set(value) {
        this.$set(this.announcement, 'cta_type', value)
        this.$set(this.announcement, 'cta_caption', null)
        this.$set(this.announcement, 'cta_button_text', null)
        this.$set(this.announcement, 'cta_value', null)
        this.$set(this.announcement, 'cta_icon', [])
      },
    },
  },
}
</script>
<style lang="">

</style>
