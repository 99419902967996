<template>
  <b-card header="Add Announcement">
    <announcement-form
      :announcement="announcement"
      :submit="addAnnouncement"
      :progress="progress"
      :is-system-admin="isSystemAdmin"
    />
  </b-card>
</template>

<script>
import AnnouncementForm from '@/common/components/Announcements/AnnouncementForm.vue'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import handleFormData from '@/common/compositions/common/handleFormData'
import announcementApi from '@/common/compositions/Announcements/announcementsApi'

export default {
  name: 'AddAnnouncement',
  components: { AnnouncementForm },
  props: {
    isSystemAdmin: { type: Boolean, default: false },
    redirectRoute: { type: String, default: 'announcements-list' },
    resource: { type: String, default: 'ActivityController' },
  },
  data() {
    return {
      announcement: {
        mediaRatio: 'landscape',
        media: [],
        entityId: this.isSystemAdmin ? null : this.$store.getters['mainEntity/getEntityId'],
        order: 0,
        ctaOrganization: null,
        ctaDonationCategory: null,
        cta_type: null,
        youtubeCover: [],
        cta_icon: [],
        sliderType: 'images',
        visibility: 'private',
      },
    }
  },
  computed: {
    formattedAnnouncement() {
      return {
        ...this.announcement,
        youtubeCover: this.announcement.youtubeCover[0],
        cta_icon: this.announcement.cta_icon[0],
        cta_value: this.announcement.cta_type === 'Donation to specific Organization'
          ? `${this.announcement.ctaOrganization.id},${this.announcement.ctaDonationCategory.id}`
          : this.announcement.cta_value,
      }
    },
  },
  provide() {
    return {
      resource: this.resource,
    }
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()
    const { formData, setFormData } = handleFormData()
    const { showPopup } = announcementApi()
    return {
      formData, setFormData, progress, calculateUploadPercentage, showPopup,
    }
  },
  methods: {
    addAnnouncement() {
      this.setFormData(this.formattedAnnouncement)
      return this.$activities.post('/internalops/announcement', this.formData,
        {
          onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
        }).then(() => {
        this.showPopup(this.formattedAnnouncement.status).then(() => {
          this.$router.push({ name: this.redirectRoute })
        })
      })
    },
  },
}
</script>

<style lang="scss">
</style>
