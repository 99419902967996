<template>
  <validation-observer
    #default="{ handleSubmit }"
  >
    <b-form
      @submit.prevent
    >
      <b-form-row>
        <!-- Title -->
        <b-col md="6">
          <text-input
            id="title"
            name="Title"
            rules="required"
            :text.sync="announcement.title"
          />
        </b-col>
      </b-form-row>

      <b-form-row>
        <!-- Start Date -->
        <b-col
          md="6"
        >
          <date-time
            id="start-date"
            name="Start Date"
            rules="required"
            :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
            :datetime.sync="announcement.startsAt"
          />
        </b-col>

        <!-- End Date -->
        <b-col
          md="6"
        >
          <date-time
            id="end-date"
            name="End Date"
            rules="required"
            :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate:$moment(announcement.startsAt).add({minutes:1}).toDate()}"
            :datetime.sync="announcement.endsAt"
          />
        </b-col>

        <!-- Visibility -->
        <b-col
          v-if="$can('publishVisibility',resource)"
          md="4"
          class="my-1"
        >
          <b-form-group
            label="Visibility"
            label-for="visibility"
          >
            <b-form-radio-group
              id="visibility"
              v-model="announcement.visibility"
              :options="[
                { text: 'Organization', value: 'private' },
                { text: 'Homepage', value: 'public' },
                { text: 'Both', value: 'both' },
              ]"
              class="mt-1"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <!-- Content -->
      <rich-text-editor
        name="Description"
        rules="max:1500"
        :description.sync="announcement.content"
      />

      <!-- Media -->
      <announcement-media
        :media.sync="announcement.media"
        :youtube-link.sync="announcement.youtubeLink"
        :youtube-cover.sync="announcement.youtubeCover"
        :media-ratio.sync="announcement.mediaRatio"
        :slider-type.sync="announcement.sliderType"
      />

      <!-- Call To Action -->
      <announcement-cta
        v-if="isSystemAdmin"
        :announcement="announcement"
      />

      <!-- Order -->
      <b-form-row>
        <b-col md="2">
          <text-input
            id="order"
            name="Order"
            rules="required|min_value:0"
            type="number"
            :text.sync="announcement.order"
          />
        </b-col>
      </b-form-row>

      <b-form-row class="my-1">
        <b-col>
          <b-form-checkbox
            id="enable-comments"
            v-model="announcement.enableComments"
            switch
          >
            Comments
          </b-form-checkbox>
        </b-col>

        <b-col>
          <b-form-checkbox
            id="enable-likes"
            v-model="announcement.enableLikes"
            switch
          >
            Likes
          </b-form-checkbox>
        </b-col>

        <b-col>
          <b-form-checkbox
            id="enable-shares"
            v-model="announcement.enableShares"
            switch
          >
            Share
          </b-form-checkbox>
        </b-col>

        <b-col
          v-if="$can('publishStatus',resource)"
        >
          <b-form-checkbox
            id="status"
            v-model="announcement.status"
            class="mb-1"
            switch
          >
            Status
          </b-form-checkbox>
        </b-col>
      </b-form-row>

      <upload-progress
        :progress="progress"
        class="mb-2"
      />

      <div class="text-right">
        <back />

        <submit-button
          :handle-submit="handleSubmit"
          :submit="submit"
          class="ml-1"
        />
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import Back from '@/common/components/common/FormInputs/Back.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import RichTextEditor from '../common/FormInputs/RichTextEditor.vue'
import DateTime from '../common/FormInputs/DateTime.vue'
import AnnouncementMedia from './AnnouncementMedia.vue'
import AnnouncementCta from './AnnouncementCTA.vue'

export default {
  name: 'AnnouncementForm',
  components: {
    Back,
    SubmitButton,
    UploadProgress,
    TextInput,
    RichTextEditor,
    DateTime,
    AnnouncementMedia,
    AnnouncementCta,
  },
  props: {
    announcement: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
    progress: { type: Number, default: null },
    isSystemAdmin: { type: Boolean, default: false },
  },
  inject: ['resource'],
}
</script>

<style lang="scss">
</style>
