var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border p-2 my-3"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Media ")]),_c('b-form-group',{attrs:{"label":"Placeholder Size","label-for":"placeholder-size"}},[_c('b-select',{attrs:{"options":[
        {text:'Please select ratio',value:null,disabled:true},
        {text:'Landscape 16x9',value:'landscape'},
        {text:'Portrait 9x16',value:'portrait'},
        {text:'Square 1x1',value:'square'}
      ]},model:{value:(_vm.mediaRatioProxy),callback:function ($$v) {_vm.mediaRatioProxy=$$v},expression:"mediaRatioProxy"}})],1),_c('b-form-group',{attrs:{"label":"Slider Type","label-for":"slider-type"}},[_c('b-form-radio-group',{attrs:{"id":"slider-type","options":[
        { text: 'Video', value: 'video' },
        { text: 'Image gallery', value: 'images' } ]},model:{value:(_vm.sliderTypeProxy),callback:function ($$v) {_vm.sliderTypeProxy=$$v},expression:"sliderTypeProxy"}})],1),(_vm.sliderTypeProxy === 'video')?_c('b-form-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('text-input',{attrs:{"id":"youtube-link","name":"Youtube Link","rules":("youtube-url|" + (_vm.isEditForm ?'':'required')),"text":_vm.youtubeLinkProxy},on:{"update:text":function($event){_vm.youtubeLinkProxy=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('upload-files',{attrs:{"id":"announcement-yt-image","label":"Youtube Cover","accept":"image/*","rules":("image|image-aspect-ratio:" + _vm.mediaRatioDimensions),"files":_vm.youtubeCoverProxy},on:{"update:files":function($event){_vm.youtubeCoverProxy=$event}},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',[_c('images-preview',{attrs:{"images":_vm.youtubeCoverProxy},on:{"update:images":function($event){_vm.youtubeCoverProxy=$event}}})],1)]},proxy:true}],null,false,3572451316)})],1)],1)],1)],1):_c('b-form-row',{staticClass:"my-1 border rounded p-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('upload-files',{attrs:{"id":"announcement-images","label":"Upload Images","accept":"image/*","rules":("image|image-aspect-ratio:" + _vm.mediaRatioDimensions + "|" + (_vm.isEditForm ?'':'required')),"multiple":"","files":_vm.imagesProxy},on:{"update:files":function($event){_vm.imagesProxy=$event}},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',[_c('images-preview',{attrs:{"images":_vm.imagesProxy},on:{"update:images":function($event){_vm.imagesProxy=$event}}})],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }